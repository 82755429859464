body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body > header, body > footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

article {
  flex-grow: 1;
}

article header {
  margin-bottom: 1.5em;
}

article header h1 {
  font-size: 1.8em;
  margin: 0 0 0.1em;
}

nav {
  margin: 0.5em -1em;
}

nav a {
  margin: 0.5em 1em;
}